$border: 1px solid black;
$nav-width: 30vw;
$nav-padding: 2vw;
$content-padding: 3vw;

$ipad: 812px;
$phone: 430px;
$rot: 480px;

* {
  font-weight: 100;
  font-family: "Oswald", sans-serif;
}
html {
  overflow-y: scroll;
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: black;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Staatliches", cursive;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  margin-top: 5vh;
}

h2,
h3,
h4 {
  color: white;
  background-color: black;
  display: inline-block;
  padding-right: 15vw;
  margin-top: 1vh;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh;
}

#content-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

.header-left {
  width: 100vw;
  .title {
    top: 0;
    padding-top: 6vh;
    line-height: 6rem;
    h1 {
      padding: 1vh 2vw;
      padding-bottom: 1vh;
      top: 0;
      font-size: 8rem;
      margin: 0;
      height: fit-content;
    }
    h2 {
      margin: 0;
      color: white;
      background: black;
      line-height: 1.7rem;
      width: 98vw;
      padding: 2vh 0;
      padding-left: 2vw;
      font-size: 2rem;
    }
  }
  .content {
    @media (min-width: $ipad) {
      width: 66vw - $content-padding;
    }
    // width: 100vw - $content-padding;
    padding: 0 $content-padding;
    font-size: 1.32rem;
  }
}

.photo-section {
  .photo-content {
    display: flex;
    flex-wrap: wrap;
  }
  p {
    width: 10vw;
    margin: 0;
    font-size: 1rem;
  }
  .img-container {
    align-self: baseline;
    padding: 1vw;
    img {
      // border: $border;
      max-width: 40vw;
      max-height: 30vh;
    }
    p {
      width: 25vw;
    }
  }
}

.nav-btn {
  @media (min-width: $ipad) {
    display: none;
  }
  position: fixed;
  right: 0;
  z-index: 100;
  margin: 0;
  background-color: black;
  color: white;
  text-align: right;
  font-size: 2.1rem;
  margin-left: 0;
  padding-right: $nav-padding;
}

nav {
  overflow-x: hidden;
  right: 0;
  @media (min-width: $ipad) {
    width: $nav-width;
  }
  @media (max-width: $ipad) {
    width: 100vw;
  }
  position: fixed;

  .menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: $ipad) {
      width: $nav-width;
    }
    @media (max-width: $ipad) {
      width: 100vw;
    }
    right: 0;
    margin: 0;
    height: 100vh;
    position: fixed;
    background-color: black;
    font-size: 2.1rem;

    .element {
      margin: 0;
      background-color: black;
      box-sizing: border-box;
      width: 100vw - $nav-padding;
      padding-right: 0;
      padding-bottom: 1vh;
      text-decoration: none;
      color: white;
      .element-content {
        display: flex;
        flex-direction: column;
        padding-left: $nav-padding;
        background-color: black;
        h2 {
          margin: 0;
        }
        h4 {
          margin: 0;
          padding: 0;
          line-height: 3rem;
        }

        .submenu-item {
          margin-left: 1vw;
          padding-bottom: 0.5vh;
        }
      }
    }
  }

  .bottom-nav {
    z-index: 1000;
    h4,
    h5 {
      margin: 0;
      padding: 0;
      padding-left: $nav-padding;
    }
    margin: 0;
    padding: 0;
    color: white;
    bottom: 0;
  }
}
#home-page {
  flex: auto;
  flex-wrap: wrap;
  img {
    // border: $border;
    margin-right: 1rem;
    float: left;
    height: 200px;
  }
}
#la-danza-page {
  .content {
    cursor: pointer;
  }
}

#textos-container {
  section {
    padding-bottom: 2vh;
  }
  ul {
    list-style-type: square;
  }
  h5 {
    margin: 0;
    display: inline;
  }
  a {
    font-family: "Staatliches", cursive;
  }
}

#pretextos-page {
  img {
    width: 100%;
  }
  ul {
    list-style-type: square;
  }
  a {
    font-family: "Staatliches", cursive;
  }
}
